<template>
    <div class="breadcrumbs">
        <div class="breadcrumbs-main">
            <span v-for="(item, index) in breadcrumbsData" :key="item.name" class="breadcrumbs-item">
                <router-link v-if="item.url" class="breadcrumbs-link" :to="item.url">{{ item.name }}</router-link>
                <span v-else class="breadcrumbs-current">{{ item.name }}</span>
                <i v-if="index!==breadcrumbsData.length-1" class="breadcrumbs-separator">|</i>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Breadcrumbs',
    props: {
        breadcrumbsData: {
            type: Array,
            default: null
        }
    }
}
</script>
<style lang="scss" scoped>
.breadcrumbs {
    min-width: 1200px;
    background-color: $white;
    color: $light;
}
.breadcrumbs-main {
    margin: 0 auto;
    width: 1200px;
    padding: 20px 0;
}
.breadcrumbs-separator {
    @include inlineBlock();
    vertical-align: 1px;
    padding: 0 5px;
}
</style>
