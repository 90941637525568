<template>
    <div v-if="information" class="fitbox-side-box">
        <div class="fitbox-side-head">
            <h3 class="fitbox-side-title">{{ title }}</h3>
        </div>
        <div class="fitbox-side-body">
            <router-link target="_blank" :to="`/product/detail?commodityid=${information.commodityid}&commodity_details_id=${information.commodity_details_id}`">
                <img class="detail-side-img" :src="information.img" width="245" height="245" :alt="information.title">
                <span class="detail-side-name text-ellipsis" :title="information.title">{{ information.title }}</span>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'SideInformation',
    props: {
        title: {
            type: String
        },
        information: {
            type: Object,
            default: null
        }
    }
}
</script>
<style lang="scss" scoped>
.fitbox-side-title {
    font-weight: normal;
    font-size: 16px;
    color: $light;
}
.detail-side-img {
    object-fit: cover;
}
.detail-side-name {
    display: block;
    height: 20px;
    line-height: 20px;
}
</style>
